<!-- src/components/RegionProperty.vue -->
<template>
  <div class="region-property-container container my-5">
    <h2 class="mb-4 text-center">Propiedades en la Región {{ regionName }}</h2>
    <p class="mb-4 text-center">Listado de comunas y propiedades disponibles en la región de {{ regionName }}.</p>
    
    <div class="row">
      <!-- Sidebar Izquierdo: Lista de Comunas -->
      <div class="col-lg-3 mb-4">
        <div class="card shadow-sm">
          <div class="card-header bg-primary text-white">
            <h5 class="mb-0">Comunas</h5>
          </div>
          <div class="card-body p-0">
            <ul class="list-group list-group-flush">
              <li 
                v-for="comuna in comunas" 
                :key="comuna.comuna" 
                class="list-group-item d-flex justify-content-between align-items-center comuna-item"
                @click="filterByComuna(comuna.comuna)"
                :class="{ active: selectedComuna === comuna.comuna }"
                style="cursor: pointer;">
                <span>{{ comuna.comuna }}</span>
                <span class="badge bg-secondary rounded-pill">{{ comuna.count }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Contenido Principal: Lista de Propiedades -->
      <div class="col-lg-9">
        <!-- Estado de Carga -->
        <div v-if="loading" class="text-center my-5">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </div>

        <!-- Mensaje de Error -->
        <div v-if="error" class="alert alert-danger">
          {{ error }}
        </div>

        <!-- Lista de Propiedades -->
        <div v-if="!loading && !error" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          <div class="col" v-for="propiedad in paginatedProperties" :key="propiedad.id">
            <div class="card tarjeta-propiedad h-100 shadow-sm">
              <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none">
                <div class="imagen-propiedad position-relative">
                  <div class="tipo-operacion badge bg-primary position-absolute top-0 end-0 m-2">
                    {{ propiedad.transaction_type }}
                  </div>
                  <img
                    :src="propiedad.images && propiedad.images.length > 0 ? propiedad.images[0] : require('@/assets/regions/default-property.jpg')"
                    class="card-img-top"
                    :alt="'Imagen de ' + propiedad.title"
                    @error="handleImageError($event)">
                  <div class="precio-propiedad badge bg-success position-absolute bottom-0 start-0 m-2">
                    {{ propiedad.currency }} {{ formatPrice(propiedad.price) }}
                  </div>
                </div>
              </router-link>
              <div class="card-body">
                <h5 class="card-title">
                  <router-link :to="{ name: 'PropertyDetail', params: { id: propiedad.id } }" class="text-decoration-none text-dark">
                    {{ propiedad.title }}
                  </router-link>
                </h5>
                <div class="tipo-propiedad fw-bold mb-2">
                  {{ propiedad.property_type }}
                </div>

                <!-- Información de la Propiedad -->
                <div class="info-propiedad">
                  <p class="mb-1">
                    <strong>{{ propiedad.rooms }}</strong> Dormitorios |
                    <strong>{{ propiedad.bathrooms }}</strong> Baños
                  </p>
                  <p class="mb-1">
                    {{ propiedad.floor_area }} m² totales |
                    {{ propiedad.parking }} Estacionamientos
                  </p>
                  <p class="mb-1">
                    <strong>Comuna:</strong> {{ propiedad.city }}
                  </p>
                </div>
                <div class="agente-propiedad mt-2">
                  <span class="text-muted">Agente: {{ propiedad.agent_name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mensaje Informativo si No Hay Comunas -->
        <div v-if="!loading && !error && comunas.length === 0" class="alert alert-warning">
          No se encontraron comunas para la región seleccionada.
        </div>

        <!-- Paginación -->
        <nav aria-label="Page navigation" v-if="totalPages > 1">
          <ul class="pagination justify-content-center">
            <!-- Botón de Página Anterior -->
            <li :class="['page-item', { disabled: currentPage === 1 }]">
              <a class="page-link" href="#" aria-label="Anterior" @click.prevent="changePage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>

            <!-- Páginas -->
            <li
              v-for="page in paginationRange"
              :key="page"
              :class="['page-item', { active: currentPage === page }, { disabled: page === '...' }]">
              <a
                v-if="page !== '...'"
                class="page-link"
                href="#"
                @click.prevent="changePage(page)">
                {{ page }}
              </a>
              <span v-else class="page-link disabled">...</span>
            </li>

            <!-- Botón de Página Siguiente -->
            <li :class="['page-item', { disabled: currentPage === totalPages }]">
              <a class="page-link" href="#" aria-label="Siguiente" @click.prevent="changePage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegionProperty',
  props: ['name'], // Nombre de la región pasado como prop
  data() {
    return {
      regionName: '',           // Añadido para hacer reactivo el nombre de la región
      comunas: [],              // Lista de comunas con conteo de propiedades obtenida de la API
      selectedComuna: null,     // Comuna seleccionada para filtrar
      properties: [],           // Todas las propiedades de la región (y posiblemente filtradas por comuna)
      loading: true,
      error: null,
      currentPage: 1,
      itemsPerPage: 12,         // Número de propiedades por página
      perPageOptions: [12, 24, 48],
      total: 0,
    };
  },
  computed: {
    // Número total de páginas
    totalPages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    // Rango de páginas para mostrar en la paginación
    paginationRange() {
      const range = [];
      const delta = 2;

      const start = Math.max(2, this.currentPage - delta);
      const end = Math.min(this.totalPages - 1, this.currentPage + delta);

      range.push(1);

      if (start > 2) {
        range.push('...');
      }

      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      if (end < this.totalPages - 1) {
        range.push('...');
      }

      if (this.totalPages > 1) {
        range.push(this.totalPages);
      }

      return range;
    },
    // Propiedades a mostrar en la página actual
    paginatedProperties() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.properties.slice(start, end);
    },
  },
  methods: {
    // Formatea el precio con puntos como separadores de miles
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    // Función para obtener las comunas de la región desde la API
    fetchComunasForRegion() {
      const comunasApiUrl = '/comunas-api/comuna'; // Ruta correcta según la configuración del proxy

      // Obtener el nombre de la región sin el prefijo "REGIÓN " o "Region "
      const apiRegionName = this.getApiRegionName(this.regionName);

      const params = {
        region: apiRegionName, // Enviar "METROPOLITANA"
      };

      console.log(`Solicitando comunas para la región: ${apiRegionName}`);
      console.log(`URL de la API de Comunas: ${comunasApiUrl}`);
      console.log('Parámetros:', params);

      axios
        .get(comunasApiUrl, { params })
        .then((response) => {
          console.log('Respuesta de la API de Comunas:', response.data);
          if (response.data && Array.isArray(response.data)) {
            // Asignar las comunas directamente ya que la API ya las filtra
            this.comunas = response.data
              .map(comuna => ({
                comuna: comuna.comuna,
                count: comuna.count,
              }))
              .sort((a, b) => b.count - a.count); // Ordenar de mayor a menor
            console.log('Comunas asignadas:', this.comunas);
            // Después de obtener las comunas, obtener las propiedades
            this.fetchProperties();
          } else {
            console.warn('Respuesta inesperada de la API de Comunas.');
            this.comunas = [];
            this.error = 'No se pudo procesar la información de comunas.';
            this.loading = false;
          }
        })
        .catch((err) => {
          console.error('Error al obtener comunas:', err);
          if (err.response) {
            console.error('Código de estado:', err.response.status);
            console.error('Datos de la respuesta:', err.response.data);
            if (err.response.status === 403) {
              this.error = 'Acceso denegado a las comunas. Por favor, verifica tus permisos.';
            } else {
              this.error = `Error al obtener comunas: ${err.response.statusText}`;
            }
          } else if (err.request) {
            console.error('Solicitud hecha pero no hubo respuesta:', err.request);
            this.error = 'No se recibió respuesta de la API de comunas.';
          } else {
            console.error('Error en la configuración de la solicitud:', err.message);
            this.error = 'Error al configurar la solicitud para obtener comunas.';
          }
          this.comunas = [];
          this.loading = false;
        });
    },
    // Función para obtener las propiedades de la región desde la API con filtros
    fetchProperties() {
      const propertiesApiUrl = '/api/search'; // Ruta correcta según la configuración del proxy

      const params = {
        state: this.regionName,   // Usar el nombre de la región
        page: this.currentPage,
        page_size: this.itemsPerPage,
      };

      if (this.selectedComuna) {
        params.city = this.selectedComuna;
      }

      console.log('Solicitando propiedades con los siguientes parámetros:', params);

      axios
        .get(propertiesApiUrl, { params })
        .then((response) => {
          console.log('Respuesta de la API de Propiedades:', response.data);
          if (response.data && response.data.properties) {
            this.properties = response.data.properties.map((propiedad) =>
              this.processProperty(propiedad)
            );
            this.total = response.data.total;
            console.log('Propiedades asignadas:', this.properties);
          } else {
            this.error = 'No se encontraron propiedades en la región seleccionada.';
            this.properties = [];
            this.total = 0;
          }
        })
        .catch((err) => {
          console.error('Error al obtener propiedades:', err);
          if (err.response) {
            console.error('Código de estado:', err.response.status);
            console.error('Datos de la respuesta:', err.response.data);
            if (err.response.status === 403) {
              this.error = 'Acceso denegado a las propiedades. Por favor, verifica tus permisos.';
            } else {
              this.error = `Error al obtener propiedades: ${err.response.statusText}`;
            }
          } else if (err.request) {
            console.error('Solicitud hecha pero no hubo respuesta:', err.request);
            this.error = 'No se recibió respuesta de la API de propiedades.';
          } else {
            console.error('Error en la configuración de la solicitud:', err.message);
            this.error = 'Error al configurar la solicitud para obtener propiedades.';
          }
          this.properties = [];
          this.total = 0;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Procesa cada propiedad según tus necesidades (similar a HomePage.vue)
    processProperty(propiedad) {
      const transactionTypeValue =
        propiedad.transaction_type ||
        this.getAttributeValue(propiedad.attributes, 'TransactionType');

      const transactionTypeDescripcion = this.getOperacionDescripcion(transactionTypeValue);

      const processed = {
        id: propiedad.id,
        title: propiedad.title,
        description: propiedad.description,
        price: propiedad.price,
        currency: propiedad.currency,
        property_type:
          propiedad.property_type ||
          this.getAttributeValue(propiedad.attributes, 'PropertyType'),
        transaction_type: transactionTypeDescripcion,
        rooms: this.getAttributeValue(propiedad.attributes, 'Rooms'),
        bathrooms: this.getAttributeValue(propiedad.attributes, 'Bathrooms'),
        floor_area: this.getAttributeValue(propiedad.attributes, 'FloorArea'),
        plot_area: this.getAttributeValue(propiedad.attributes, 'PlotArea'),
        year: this.getAttributeValue(propiedad.attributes, 'Year'),
        parking: this.getAttributeValue(propiedad.attributes, 'Parking'),
        cellar: this.getAttributeValue(propiedad.attributes, 'Cellar'),
        orientation: this.getAttributeValue(propiedad.attributes, 'Orientation'),
        is_furnished: this.getAttributeValue(propiedad.attributes, 'IsFurnished') === 'True',
        agent_name: propiedad.agency ? propiedad.agency.contactname : '',
        agent_email: propiedad.agency ? propiedad.agency.contactemail : '',
        agent_phone: propiedad.agency ? propiedad.agency.contactphones : '',
        location: propiedad.location,
        city: propiedad.city || (propiedad.location ? propiedad.location.city : ''),
        images: propiedad.pictures ? propiedad.pictures.map((pic) => pic.source) : [],
      };

      console.log('Propiedad procesada:', processed);
      return processed;
    },
    // Función para obtener el valor de un atributo por su nombre
    getAttributeValue(attributes, name) {
      if (!attributes || !Array.isArray(attributes)) {
        console.warn('Attributes no está definido o no es un array.');
        return '';
      }
      const attr = attributes.find((attr) => attr.name === name);
      return attr ? attr.value : '';
    },
    // Función para obtener la descripción de la operación
    getOperacionDescripcion(valor) {
      // Puedes eliminar esta función si no usas el filtro de operación
      // Si no utilizas operaciones, considera remover esta función para limpiar el código
      // Manteniéndola por ahora en caso de que la necesites en el futuro
      if (!this.operaciones || this.operaciones.length === 0) {
        console.warn('La lista de operaciones no está definida.');
        return valor;
      }
      console.log(`Buscando descripción para el valor: ${valor}`);
      const operacion = this.operaciones.find(
        (op) => op.valor === valor || op.descripcion === valor
      );
      console.log('Operación encontrada:', operacion);
      return operacion ? operacion.descripcion : valor;
    },
    // Filtrar las propiedades por comuna desde el sidebar
    filterByComuna(comuna) {
      if (this.selectedComuna === comuna) {
        // Si ya está seleccionada, deseleccionar
        this.selectedComuna = null;
      } else {
        this.selectedComuna = comuna;
      }
      this.currentPage = 1; // Reiniciar a la primera página al filtrar
      this.loading = true;
      this.error = null;
      this.fetchProperties();
    },
    // Cambiar a una página específica
    changePage(page) {
      if (page < 1) return;
      if (page > this.totalPages) return;
      this.currentPage = page;
      this.loading = true;
      this.error = null;
      this.fetchProperties();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    // Manejar errores de carga de imágenes
    handleImageError(event) {
      event.target.src = require('@/assets/regions/default-property.jpg'); // Imagen por defecto
    },
    // Helper para obtener el nombre de región para la API
    getApiRegionName(regionFullName) {
      // No eliminar "REGIÓN " ya que la API ya filtra las comunas por región
      // Simplemente retornar el nombre en mayúsculas
      return regionFullName.toUpperCase();
    },
  },
  mounted() {
    // Asignar el nombre original de la región en mayúsculas para la API
    this.regionName = this.name.toUpperCase();
    console.log(`Nombre de la región para la API: ${this.regionName}`);
    
    // Obtener las comunas y luego las propiedades
    this.fetchComunasForRegion();
  },
  watch: {
    // Observa cambios en selectedOperacion para aplicar filtros (eliminado ya que no se usa)
    // selectedOperacion() {
    //   this.currentPage = 1;
    //   this.fetchProperties();
    // },
  },
};
</script>

<style scoped>
.region-property-container {
  padding: 20px;
}

.region-property-container h2 {
  margin-bottom: 20px;
}

.region-property-container p {
  margin-bottom: 30px;
}

.card {
  border: none;
}

.list-group-item {
  transition: background-color 0.3s, color 0.3s;
}

.list-group-item.active {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

.list-group-item:hover {
  background-color: #f1f1f1;
  color: #0d6efd;
}

.comuna-item {
  padding: 15px 20px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.badge {
  font-size: 0.9rem;
}

.tarjeta-propiedad {
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  position: relative;
}

.tarjeta-propiedad:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.imagen-propiedad {
  position: relative;
}

.tipo-operacion {
  /* Puedes personalizar el color y el estilo según tu preferencia */
}

.precio-propiedad {
  /* Puedes personalizar el color y el estilo según tu preferencia */
}

.tipo-propiedad {
  color: #FF8C00; /* Naranja oscuro para resaltar */
  font-weight: bold;
  text-align: left;
}

.info-propiedad p {
  text-align: left;
  color: #585858;
  margin-bottom: 0.5rem;
}

.agente-propiedad {
  margin-top: 12px;
  font-size: 0.875rem;
  text-align: left;
}

.card-img,
.card-img-top {
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

.card-title a {
  margin-bottom: 0.5rem;
  color: #343a40;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
}

.card-title a:hover {
  color: #0d6efd;
}

.tipo-propiedad.fw-bold {
  text-align: left;
  color: #343a40;
}

.pagination {
  margin-top: 20px;
}

.pagination .page-link {
  cursor: pointer;
}

@media (max-width: 992px) {
  /* Ajustes para pantallas medianas y pequeñas */
  .col-lg-3 {
    order: 2; /* Mover el sidebar debajo en pantallas pequeñas */
  }
  .col-lg-9 {
    order: 1;
  }
}
</style>
